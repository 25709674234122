import { css, Global } from "@emotion/react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import * as React from "react"
import { Layout } from "../components/Layout"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 1.4rem;
`

const IndexPage = props => {
  return (
    <Layout location={props.location}>
      <Wrapper>
        <Link to="/pages">Pages...</Link>
      </Wrapper>
    </Layout>
  )
}

export default IndexPage
